/*----------------------------------*\
  #TYPOGRAPHY
\*----------------------------------*/


// Font stacks

$font-stack-primary : 'Walsheim', Arial, sans-serif;
$font-stack-titles   : 'Chronicle Display A', 'Chronicle Display B', Georgia, serif;


// Font settings

$font-stack-body      : $font-stack-primary;
$font-stack-buttons   : $font-stack-primary;


// Font sizes

$font-size-root       : 10px;       // The font size set on the root html element.


$font-size-xxxs       : 1.2rem;     // Smaller font-size

$font-size-xxs        : 1.4rem;     // Smaller font-size

$font-size-xs         : 1.6rem;     // Smaller font-size

$font-size-body       : 1.8rem;     // Regular font size

$font-size-h1         : 3.2rem;

$font-size-h2         : 3.2rem;

$font-size-h3         : 2.2rem;

$font-size-h4         : 1.6rem;

$font-size-h5         : 1.3rem;

$font-size-h6         : 1.1rem;


// Font line heights
// Divide the the given line height by the font size to give an unitless line height
// @example {design line height} / {font size} = {unitless lineheight}
//
// NOTE: Never use px as line-height value!!!

$font-line-height-body: 1.67; // {design-line-height} / strip-unit($font-size-body);

$font-line-height-h1:   1.43; // {design-line-height} / strip-unit($font-size-h1);

$font-line-height-h2:   1.43; // {design-line-height} / strip-unit($font-size-h2);

$font-line-height-h3:   1.5; // {design-line-height} / strip-unit($font-size-h3);

$font-line-height-h4:   1.5; // {design-line-height} / strip-unit($font-size-h4);

$font-line-height-h5:   1.5; // {design-line-height} / strip-unit($font-size-h5);

$font-line-height-h6:   1.5; // {design-line-height} / strip-unit($font-size-h6);


// Font weights
$font-weight-ultralight : 100;

$font-weight-light      : 200;

$font-weight-book       : 300;

$font-weight-regular    : 400;

$font-weight-medium     : 500;

$font-weight-bold       : 600;

$font-weight-extrabold  : 700;

$font-weight-black      : 800;
