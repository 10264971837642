/*----------------------------------*\
  #COLORS
\*----------------------------------*/

// Color palette

$dev-color-primary		          : #ff5454;  // Tam Tam red

// Colors Debug data

$dev-color-anthracite             : #2b2a27;  // Anthracite
$dev-color-orange                 : #ff5d38;  // Orange
$dev-color-turquoise              : #26a6a6;  // Turquoise
$dev-color-lime                   : #bcd42a;  // Lime
$dev-color-yellow-light           : #ede0ce;  // Light yellow

$dev-color-grey                   : #cacaca;  // Grey
$dev-color-grey-light             : #808080;  // Grey light
$dev-color-grey-dark              : #333333;  // Grey dark

$dev-color-white                  : #ffffff;  // Off white
$dev-color-off-white              : #ececec;  // Off white

$dev-color-black                  : #000000;  // Black