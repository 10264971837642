@charset "UTF-8";
/*------------------------------------*\
  #MAIN
\*------------------------------------*/
/**
 * CONTENTS
 *
 * UTILITIES
 * Custom..................Custom-specific utilities that are being used by settings.
 *
 * SETTINGS
 * All.....................Because our Settings layer doesn’t actually produce any
 *                         CSS, we can safely glob all of the files into one import
 *                         without risk of bloating our compiled stylesheet. This
 *                         also allows us to easily recycle all of our
 *                         project-level settings into other Sass file/projects.
 *                         Please see `00_settings/_all.scss` for a full table of
 *                         contents.
 *
 * TOOLS
 * All.....................Because our Tools layer doesn’t actually produce any
 *                         CSS, we can safely glob all of the files into one import
 *                         without risk of bloating our compiled stylesheet. This
 *                         also allows us to easily recycle all of our
 *                         project-level tooling into other Sass file/projects.
 *                         Please see `01_tools/_all.scss` for a full table of
 *                         contents.
 *
 * GENERIC
 * Normalize...............A level playing field.
 * Html....................Default HTML styling
 *
 *
 * ELEMENTS
 * All.....................Because our Tools layer doesn’t actually produce any
 *                         CSS, we can safely glob all of the files into one import
 *                         without risk of bloating our compiled stylesheet. This
 *                         also allows us to easily recycle all of our
 *                         project-level tooling into other Sass file/projects.
 *                         Please see `20_elements/_all.scss` for a full table of
 *                         contents.
 *
 * OBJECTS
 * Background image........Background position / styling classes
 * Background..............Background color classes
 *
 * COMPONENTS
 * Button..................Specific styling for Buttons based on classes
 *
 * SCOPES
 * Rich text...............Set up a new styling context for long-format text.
 *
 * UTILITIES
 * Flex....................Flexbox classes
 * Text....................Text classes
 *
 */
/*----------------------------------*\
  #SETTINGS
\*----------------------------------*/
/*----------------------------------*\
  #MEDIA
\*----------------------------------*/
/*----------------------------------*\
  #TYPOGRAPHY
\*----------------------------------*/
/*----------------------------------*\
  #COLORS
\*----------------------------------*/
/*----------------------------------*\
  #EASES
\*----------------------------------*/
/*----------------------------------*\
  #GRID
\*----------------------------------*/
/*----------------------------------*\
  #SHADOWS
\*----------------------------------*/
/*----------------------------------*\
  #SPACING
\*----------------------------------*/
/*----------------------------------*\
  #SETTINGS
\*----------------------------------*/
/*----------------------------------*\
  #COLORS
\*----------------------------------*/
/**
 * Media queries targeting a device and smaller
 **/
/**
 * Media queries targeting a device and bigger
 **/
/**
 * Media queries targeting a device and bigger
 * - - screen-mobile-only? > use screen-mobile-and-smaller
 * - - screen-desktop-only? > use screen-desktop-and-bigger
 **/
/**
 * Custom Media queries
 **/
#__bs_notify__ {
  top: auto !important;
  bottom: 0 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 0px !important; }

/*

    Styling for the debug log that prints out context data.
    Can be injected anywhere so we use !important to make sure no style is overwritten.

    */
.c-debug-data-log {
  width: 100%                    !important;
  min-width: 600px                   !important;
  max-height: 400px                   !important;
  background-color: #2b2a27 !important;
  font-family: consolas, menlo, monaco, monospace !important;
  font-size: 12px                    !important;
  overflow: scroll                  !important;
  padding: 5px 10px                !important;
  z-index: 999                     !important;
  text-align: left                    !important; }
  .c-debug-data-log .function {
    color: #ff5d38 !important; }
  .c-debug-data-log .string {
    color: #26a6a6 !important; }
  .c-debug-data-log .number {
    color: #bcd42a !important; }
  .c-debug-data-log .boolean {
    color: #ff5d38 !important; }
  .c-debug-data-log .null {
    color: #ff5d38 !important; }
  .c-debug-data-log .key {
    color: #ede0ce !important; }

.c-grid-overlay [class*="col-"] {
  background: #ff5454;
  box-shadow: 15px -15px 0 0 #540000 inset, -15px -15px 0 0px #000000 inset; }
  @media only screen and (max-width: 29.96875em) {
    .c-grid-overlay [class*="col-"] {
      box-shadow: 10px -10px 0 0 #540000 inset, -10px -10px 0 0px #000000 inset; } }

.c-grid-overlay {
  display: none !important;
  height: 100%;
  left: 50%;
  position: fixed;
  pointer-events: none;
  top: 0;
  transform: translate3d(-50%, 0, 0);
  width: 100%;
  z-index: 99; }
  @media screen and (min-width: 480px) and (max-width: 1300px) {
    .c-grid-overlay {
      left: 0;
      transform: none; } }
  .c-grid-overlay.is--default-visible {
    display: block !important; }
  .c-grid-overlay [class*="col-"] {
    height: 100vh;
    opacity: .06; }

.grid-overlay__current-viewport {
  bottom: 0;
  font-size: 1.3rem;
  right: 0;
  position: fixed;
  text-transform: none;
  z-index: 4;
  background-color: #ff5454;
  padding: 12px;
  color: #ffffff;
  font-weight: bold;
  pointer-events: none; }
  .grid-overlay__current-viewport:hover {
    opacity: 0.3; }

.grid-overlay__toggle.c-button {
  bottom: 0;
  font-size: 1.3rem;
  left: 0;
  position: fixed;
  text-transform: none;
  z-index: 4; }
  .grid-overlay__toggle.c-button:hover {
    opacity: 0.3; }

.grid-overlay__check {
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden; }

.grid-overlay__check:checked + .c-grid-overlay {
  display: block !important; }
  .grid-overlay__check:checked + .c-grid-overlay.is--default-visible {
    display: none !important; }

.c-project-info {
  margin: 60px auto; }
  .c-project-info code {
    font-family: consolas, menlo, monaco, monospace !important;
    font-size: 15px !important; }
  .c-project-info p {
    margin-bottom: 1em; }
  .c-project-info a {
    color: #ff5454;
    padding-bottom: 1px;
    border-bottom: 1px solid rgba(255, 84, 84, 0);
    transition: border-bottom-color 200ms ease; }
    .c-project-info a:hover {
      border-bottom-color: #ff5454; }

.c-project-info__directory-list {
  list-style: none; }
  .c-project-info__directory-list li:before {
    content: "\2015";
    position: relative;
    left: -5px; }
  .c-project-info__directory-list ul {
    margin-left: 20px; }
    .c-project-info__directory-list ul li:before {
      content: "\2013";
      position: relative;
      left: -5px; }

.c-project-info__code-doc {
  font-size: 0; }
  .c-project-info__code-doc > div {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 18px; }
  .c-project-info__code-doc > div:nth-of-type(1) {
    width: 25%; }
  .c-project-info__code-doc > div:nth-of-type(2) {
    width: 75%;
    padding-left: 20px; }

.c-project-info__code-samp {
  margin-bottom: 40px; }
  .c-project-info__code-samp samp {
    display: block;
    background-color: #2b2a27 !important;
    font-family: consolas, menlo, monaco, monospace !important;
    font-size: 15px !important;
    color: #ede0ce !important;
    padding: 15px 10px !important; }
  .c-project-info__code-samp .function {
    color: #ff5d38 !important; }
  .c-project-info__code-samp .string {
    color: #bcd42a !important; }
  .c-project-info__code-samp .number {
    color: #bcd42a !important; }
  .c-project-info__code-samp .boolean {
    color: #ff5d38 !important; }
  .c-project-info__code-samp .null {
    color: #ff5d38 !important; }
  .c-project-info__code-samp .key {
    color: #ede0ce !important; }
  .c-project-info__code-samp .tag {
    color: #26a6a6 !important; }
  .c-project-info__code-samp .comment {
    color: #808080 !important; }
  .c-project-info__code-samp p {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 10px !important;
    color: #808080; }
    .c-project-info__code-samp p code {
      font-size: 10px !important; }

.c-project-pages-list {
  position: relative;
  margin: 20px auto;
  text-align: center;
  padding: 20px 50px;
  font-family: "Walsheim", Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: .012em; }
  .c-project-pages-list .c-project-pages-list__disclaimer {
    margin: 0 0 20px 0;
    color: #808080;
    font-size: .7em; }
  .c-project-pages-list ul {
    margin: 0 0 0 40px;
    padding: 0; }
  .c-project-pages-list > ul {
    display: inline-block;
    text-align: left;
    margin: 0;
    list-style: none; }
  .c-project-pages-list a {
    position: relative;
    padding: 4px 10px;
    margin: 0;
    color: #ececec;
    text-decoration: none;
    z-index: 2;
    transition-property: color; }
    .c-project-pages-list a, .c-project-pages-list a:before {
      transition: 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    .c-project-pages-list a:before {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
      transition-property: background-color, left, right;
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #2b2a27;
      z-index: -1; }
    .c-project-pages-list a:hover:before {
      background-color: #ff5454;
      left: -5px;
      right: -5px; }
  .c-project-pages-list li {
    position: relative;
    margin: 0 0 4px 0;
    padding: 0;
    list-style: none; }

.c-svg-collection__tile {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  position: relative;
  vertical-align: top;
  border: 1px solid #cacaca;
  padding: 1em 1em 3em 1em;
  margin-bottom: 10px;
  cursor: default; }
  .c-svg-collection__tile h4 {
    color: #333333;
    margin: 0;
    padding-bottom: 3px;
    line-height: 1em;
    font-size: 1em;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 1em; }

.c-svg-collection__container {
  margin: 0;
  width: 100%;
  height: 144px;
  position: relative;
  background: #cacaca url(data:image/gif;base64,R0lGODlhDAAMAIAAAMzMzP///yH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjEgNjQuMTQwOTQ5LCAyMDEwLzEyLzA3LTEwOjU3OjAxICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1LjEgV2luZG93cyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozQjk4OTI0MUY5NTIxMUUyQkJDMEI5NEFEM0Y1QTYwQyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDozQjk4OTI0MkY5NTIxMUUyQkJDMEI5NEFEM0Y1QTYwQyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjNCOTg5MjNGRjk1MjExRTJCQkMwQjk0QUQzRjVBNjBDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjNCOTg5MjQwRjk1MjExRTJCQkMwQjk0QUQzRjVBNjBDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAAAAAAAsAAAAAAwADAAAAhaEH6mHmmzcgzJAUG/NVGrfOZ8YLlABADs=) top left repeat;
  border: 1px solid #cacaca;
  display: block;
  text-align: center; }
  .c-svg-collection__container i {
    display: inline-block;
    text-indent: 200%;
    white-space: nowrap;
    overflow: hidden; }
  .c-svg-collection__container svg {
    display: block;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate3d(-50%, -50%, 0); }

.c-styleguide__block-fonts > p {
  display: block !important;
  font-size: 25px !important;
  margin-bottom: 20px !important; }

.c-styleguide__styleblock {
  margin: 60px auto; }

.c-styleguide__header {
  position: relative;
  margin: 0px 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #cacaca; }

.c-styleguide__title {
  display: inline-block;
  font-size: 2rem;
  color: #333333; }

.c-styleguide__disclaimer {
  display: inline-block;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 1rem;
  color: #bbaea5; }

html.u-debug_page_ {
  background-color: #ececec;
  color: #333333; }
