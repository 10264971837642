/*----------------------------------*\
  #COLORS
\*----------------------------------*/

// Color palette

$color-primary      : #6C6863;
$color-secondary    : #1E3A72;
$color-tertiary     : #F5F5F5;

$color-black        : #000000;
$color-grey-dark    : #333333;
$color-grey         : #cacaca;
$color-grey-light   : #F5F5F5;

$color-white        : #ffffff;
$color-off-white    : #ececec;


// Core color settings

$color-background-html      : $color-white;
$color-background-primary   : $color-white;

$color-text-header          : $color-black;
$color-text                 : $color-black;

$color-divider-line         : $color-grey-light;

$color-form-placeholder     : $color-grey;
$color-form-focus           : $color-primary;



// Social color palette

$color-social-twitter     : #00aced;
$color-social-facebook    : #3b5998;
$color-social-googleplus  : #dd4b39;
$color-social-pinterest   : #cb2027;
$color-social-linkedin    : #007bb6;
$color-social-youtube     : #bb0000;
$color-social-vimeo       : #aad450;
$color-social-instagram   : #517fa4;
$color-social-flickr      : #ff0084;
$color-social-dribbble    : #ea4c89;
$color-social-whatsapp    : #4dc247;

// List of social channels with their colors you can use to loop through
$color-socials-map        : ( 'twitter'   : $color-social-twitter,
                              'facebook'  : $color-social-facebook,
                              'linkedin'  : $color-social-linkedin,
                              'youtube'   : $color-social-youtube );
